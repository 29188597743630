import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import '../scss/main.scss';
import * as styles from '../scss/pages/itemPage.module.scss';
import Header from '../components/Header';
import ItemPageTextContent from '../components/ItemPageTextContent';
import MenuModal from '../components/MenuModal';
import Footer from '../components/Footer';

export default function ItemPage({ pageContext }:any) {
  const {
    title,
    contentCategory,
    mainImage,
    openDate,
    closeDate,
    curator,
    location,
    participantString,
    text,
    programDate,
    speakerString,
    issueNo,
    imageCredits,
    authorCredits,
    publicationDate,
    newsUrl,
  } = pageContext;

  const [menuOpen, setMenuOpen] = useState(false);

  function handleContentCategory(inputString: string) {
    switch (inputString) {
      case 'exhibition':
        return 'Exhibitions';
      case 'talk':
        return 'Talks';
      case 'event':
        return 'Events';
      case 'magazine':
        return 'Magazine';
      case 'book':
        return 'Books';
      case 'news':
        return 'News';
      default:
        throw new Error('Unexpected contentCategory value.');
    }
  }

  return (
    <>
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      {menuOpen ? null : (
        <main className={styles.main}>
          <div className={styles.itemContent}>
            <div className={styles.itemHeader}>
              <p className={styles.breadcrumb}>{handleContentCategory(contentCategory)}</p>
              <h2 className={styles.title}>{title}</h2>
            </div>
            <GatsbyImage
              className={styles.mainImage}
              image={mainImage.asset.gatsbyImageData}
              alt={title}
            />
            <ItemPageTextContent
              openDate={openDate}
              closeDate={closeDate}
              curator={curator}
              location={location}
              participantString={participantString}
              text={text}
              programDate={programDate}
              speakerString={speakerString}
              issueNo={issueNo}
              imageCredits={imageCredits}
              authorCredits={authorCredits}
              publicationDate={publicationDate}
              newsUrl={newsUrl}
            />
          </div>
        </main>
      )}
      {menuOpen ? null : <Footer />}
      <MenuModal menuOpen={menuOpen} />
    </>
  );
}
