import React from 'react';
import * as styles from '../scss/components/itemPageStringInfo.module.scss';

export default function SanityStringParser(props : {
  dateTime: boolean, label: string, sanityString: string
}) {
  const { dateTime, label, sanityString } = props;

  // in case of no sanity input
  if (!sanityString) return null;

  function composeDateTimeString(rawDateString: string, rawTimeString: string) {
    if (label === '발행일') return rawDateString;
    const handledHours = String((Number(rawTimeString?.substring(0, 2)) + 9) % 24);
    return `${rawDateString} @ ${handledHours}${rawTimeString?.substring(2, 5)}`;
  }

  function handleDateTime() {
    const date = sanityString.split(', ')[0];
    const time = sanityString.split(', ')[1];
    return composeDateTimeString(date, time);
  }

  function handleString() {
    if (label === '링크') {
      return <a href={sanityString}>External Link</a>;
    }
    if (dateTime) return handleDateTime();
    return sanityString;
  }

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <p className={styles.body}>{handleString()}</p>
    </div>
  );
}
