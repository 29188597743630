import React from 'react';
import SanityStringParser from './SanityStringParser';
import SanityBlockParser from './SanityBlockParser';

export default function ItemPageTextContent(props: {
  openDate: string,
  closeDate: string,
  curator: string,
  location: any,
  participantString: string,
  text: any,
  programDate: string,
  speakerString: string,
  issueNo: string,
  imageCredits: string,
  authorCredits: string,
  publicationDate: string,
  newsUrl: string,
}) {
  const {
    openDate,
    closeDate,
    curator,
    location,
    participantString,
    text,
    programDate,
    speakerString,
    issueNo,
    imageCredits,
    authorCredits,
    publicationDate,
    newsUrl,
  } = props;

  return (
    <div>
      <SanityStringParser dateTime label="시작 일시" sanityString={openDate} />
      <SanityStringParser dateTime label="마감 일시" sanityString={closeDate} />
      <SanityStringParser dateTime={false} label="기획" sanityString={curator} />
      <SanityBlockParser desc={false} label="장소" sanityBlocks={location} />
      <SanityStringParser dateTime={false} label="참여자" sanityString={participantString} />
      <SanityStringParser dateTime={false} label="Issue" sanityString={issueNo} />
      <SanityStringParser dateTime label="일시" sanityString={programDate} />
      <SanityStringParser dateTime={false} label="강연자" sanityString={speakerString} />
      <SanityStringParser dateTime={false} label="사진" sanityString={imageCredits} />
      <SanityStringParser dateTime={false} label="글" sanityString={authorCredits} />
      <SanityStringParser dateTime label="발행일" sanityString={publicationDate} />
      <SanityStringParser dateTime={false} label="링크" sanityString={newsUrl} />
      <SanityBlockParser desc label="서술" sanityBlocks={text} />
    </div>
  );
}
